@import url('https://fonts.googleapis.com/css2?family=Londrina+Sketch&family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Sketch&family=Londrina+Solid:wght@100;300;400&family=Roboto:wght@100;300;400;500;700&display=swap');
:root { 
	--primary-fill: #5fc0b3;
	--primary-fill-light: #B2FAF0;
	--primary-text: #192c43;
	--secondary-fill: #F0F3F3;
	--secondary-text: #5C6B7D;
	--border-primary: var(--primary-text);
	--border-secondary: #BFCBD9;
	--primary-link: #386396;
	--primary-link-hover: #5f88b9;
}
body {
	background-color: var(--secondary-fill);
	font-family: 'Roboto', sans-serif;
}
h1 {
	font-family: 'Londrina Solid', sans-serif;
	font-size: 5rem;
	color: var(--primary-text);
	text-align: left;
	line-height: 1;
	margin-bottom: 1.5rem;
}
h2 {
	font-family: 'Londrina Solid', sans-serif;
	font-size: 3.5rem;
	font-weight: 300;
	text-align: left;
	color: var(--secondary-text);
	line-height: 1;
	margin-top: 0;
	margin-bottom: 1.5rem;
}
h3 {
	font-size: 2.5rem;
	font-family: 'Londrina Solid', sans-serif;
	color: var(--primary-text);
	font-weight: 500;
	&.portfolio-header {
		margin: 0;
		position: absolute;
		top: -40px;
		left: 2rem;
	}
}
a {
	color: var(--primary-link);
	position: relative;
	&::before {
		content: '';
		position: absolute;
		background: var(--primary-link);
		opacity: 0;
		left: -2px;
		top: -2px;
		bottom: -2px;
		right: 100%;
		transition: right 0.5s ease;
	}
	&:hover {
		color: var(--primary-text);
		&::before {
			content: '';
			position: absolute;
			opacity: 0.1;
			right: -2px;
			transition: right 0.5s ease;
		}
	}
	&:focus {
		outline: none;
		&::before {
			content: '';
			position: absolute;
			opacity: 0.1;
			right: -2px;
			transition: right 0.5s ease;
		}
	}
	
}
p {
	line-height: 1.35;
}
h4 {
	font-size: 2rem;
	margin: 0;
	font-family: 'Londrina Solid', sans-serif;
	font-weight: 300;
}
h5 {
	margin: 0;
	font-size: 1.5rem;
	font-family: 'Londrina Solid', sans-serif;
	font-weight: 400;
	color: var(--secondary-text);
}

.App {
	text-align: center;
}
.App-header {
	background-color: var(--primary-fill);
	background: linear-gradient(var(--primary-fill-light), var(--primary-fill));
	height: 100vh;
	min-height: 740px;
	max-height: 860px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5rem;
	border-bottom: 4px solid var(--border-primary);
	padding: 0 2rem;
}
.mascot-image {
	max-height: 460px;
}
.icons {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.75rem;
}
.icon-link {
	color: var(--primary-text);
	border: 2px solid transparent;
	border-radius: 0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	position: relative;
	&::before {
		display: none;
	}
	svg {
		pointer-events: none;
	}
	.icon-tooltip {
		position: absolute;
		opacity: 0;
		left: 50%;
		transform: translateX(-50%);
		transition: 0.5s ease;
		top: 0;
		color: var(--primary-fill);
		background: var(--primary-text);
		font-size: 0.625rem;
		text-transform: uppercase;
		padding: 0.25rem;
		border-radius: 0.25rem;
		pointer-events: none;
	}
	&:hover, &:focus {
		border-color: var(--primary-text);
		.icon-tooltip {
			top: -1.5rem;
			opacity: 0.75;
			transition: 0.5s ease;
		}
	}
}
.header-about {
	max-width: 340px;
	background: rgba(56, 99, 150, 0.15);
	padding: 1rem;
	border-radius: 0.5rem;
	text-align: left;
	color: var(--primary-text);
}
.text-large {
	font-size: 1.5rem;
	font-family: 'Londrina Solid', sans-serif;
}
footer {
	font-size: 0.625rem;
	color: #333;
	padding: 1rem 0;
	background-color: var(--primary-fill);
}
main {
	background-color: var(--secondary-fill);
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	position: relative;
}
.portfolio-section {
	padding: 2rem;
	text-align: left;
	&:not(:last-child) {
		border-bottom: 1px solid var(--border-secondary);
	}
}
.portfolio-section-header {
	position: relative;
}
.portfolio-section-header-image {
	max-width: 100%;
}
.btn {
	margin-right: 10px;
	padding: 0.5rem 1rem;
	background: var(--primary-link);
	color: white;
	border-radius: 20px;
	text-decoration: none;
	transition: 0.75s ease;
	display: inline-flex;
	width: auto;
	align-items: center;
	gap: 1rem;
	&:hover {
		color: white;
		background: var(--primary-link-hover);
		transition: 0.75s ease;
	}
	&:focus-visible {
		background: var(--primary-link-hover);
		box-shadow: var(--primary-text) 0px 1px 4px, var(--primary-text) 0px 0px 0px 3px;
	}
	&::before {
		display: none;
	}
	span {
		white-space: nowrap;
	}
	.icon {
		width: 0.75rem;
		height: 0.75rem;
	}
}
.button-container {
	display: inline-flex;
	align-items: center;
	gap: 10px;
}

@media screen and (max-width: 1080px) {
	.App-header {
		display: flex;
		flex-direction: column;
		gap: 0;
		padding-top: 1rem;
		max-height: none;
		height: auto;
	}
	.mascot-image {
		max-height: 300px;
	}
	h1 {
		margin-top: 0;
		text-align: center;
		font-size: 4rem;
	}
	h2 {
		text-align: center;
		font-size: 2.5rem;
		font-family: 'Londrina Solid', sans-serif;
		font-weight: 500;
	}
	.icons {
		justify-content: center;
		margin-bottom: 2rem;
	}
	.header-about {
		max-width: 100%;
		margin: 0 1rem 3rem;
	}
	.portfolio-header {
		right: 50%;
		transform: translateX(50%);
		font-family: 'Londrina Solid', sans-serif;
		font-weight: 500;
	}
	
}
@media screen and (max-width: 767px) {
	.button-container {
		flex-direction: column;
		width: 100%;
	}
}